import {useEffect,useRef,useState} from 'react';
import Book from "./model/Book";
import TopBar from './TopBar';
import MainTable from './MainTable'
import DiscoveryInventoryHeader from './DiscoveryInventoryHeader';
import StatusBar from './StatusBar';
import { AppLayout,Spinner } from '@amzn/awsui-components-react-v3';
import { useLocation } from 'react-router-dom';
import Footer from './Footer';
import { scan_api } from "../utils/constants";
import generateBooks from "./model/BookFactory"
import {TPPSDiscoveryDataInternal } from "../utils/constants";
import { useCollection } from '@amzn/awsui-collection-hooks';
import LandingPage from './LandingPage';
import {DataGridDemo} from './DiscoveryInventoryDataGrid';
import {DiscoveryData} from './DiscoveryData';

import DataGridColumn from './model/DataGridColumns';
export default function DiscoveryInventory(props: any) {
    // getData helper function

    const [entries, setEntries] = useState<DataGridColumn[]>([]);
    const [isBusy, setBusy] = useState(true)
    const hasFetchedData = useRef(false);


    // const [entries, setEntries] = useState<Book[]>([]);

    

    // async function getData(jwt: string) {
    //   let Data: any;
    //   Data = {};
    //   let request: any;
    //   request = {}
    //   request["action"] = "getinternal";

  
    //   try {
        
    //     const response = await fetch(TPPSDiscoveryDataInternal, {
    //       mode: 'cors',
    //       method: 'POST',
    //       headers: {
    //         'Content-type': 'application/json',
    //         'Accept': 'application/json',
    //         'Authorization': jwt,
    //       },
    //       body: JSON.stringify(request)
    //     });
    //     // console.log(response)
    //     response.json().then(data => {
    //     // setEntries(JSON.parse(data));
    //     console.log(data)
    //     setEntries(data);
    //     setBusy(false);
        
    //     })
    //   }
    //   // TODO: Handle the error more gracefully by adding a pop up
    //   catch (err) {
    //     console.log(err);
    //   }
    // }

    useEffect(() => {
      async function fetchData() {
        let Data: any;
        Data = {};
        let request: any;
        request = {}
        request["action"] = "getinternal";
        try {
          await fetch(TPPSDiscoveryDataInternal, {
                  mode: 'cors',
                  method: 'POST',
                  headers: {
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': props.userInfo.jwt,
                  },
                  body: JSON.stringify(request)
                })
          .then(response => response.json())
          .then(data => {setEntries(data);setBusy(false);});
        } catch (e) {
          console.error('Error fetching api data', e);
        };
      };
      if (hasFetchedData.current === false) {
        fetchData();
        hasFetchedData.current = true;
      } 
  }, []);
  // useEffect(() => {
  //   getData(props.userInfo.jwt);
    
  // }, [props.userInfo.jwt]);

  
    // const sampledata = generateBooks(101);
    // console.log(sampledata)
   console.log(entries.length)
  //  JSON.parse(entries)
   
    
    // Logic for displaying the success message in the flash bar.
    // This takes the location object containig props when
    // using navigate
    const stat: any = useLocation();
    let status = stat?.state?.submitStatus;
    return (
        <div style={{width :1800}}>
          <TopBar userInfo={props.userInfo} />
          
            {isBusy ? (
                  <Spinner />
                ) : (
            
                  <AppLayout
                contentType="form"
                // contentHeader={<DiscoveryInventoryHeader />}
                // notifications={status ? <StatusBar submitStatus={status} /> : null}
              
                  // content={<DataGridDemo rows={sampledata}/>}
                 content={<DiscoveryData rows={entries} jwt_token={props.userInfo.jwt} />}
                 
                navigationHide={true}
                toolsHide={true} 
               
                />
                )}
                 <Footer/>
                
        </div>
    );
}