import {
  Container,
  Form,
  FormField,
  Header,
  Input,
  SpaceBetween,
  Select,
  RadioGroup,
  Textarea,
  Button,
  ColumnLayout,
} from "@cloudscape-design/components";
import { useForm, Controller } from "react-hook-form";
import { NavigateFunction, useNavigate } from "react-router";
import {create_api2} from '../../../utils/constants';


export default function SimpleRegistrationContent(props: any){

  const navigate: NavigateFunction = useNavigate();
  function onClickHandler() {
      navigate("/");
    }
  
  const { control, getValues, handleSubmit, formState: { errors } } = useForm();



  async function onSubmit(data: any) {

      navigate("/", { state: { submitStatus: "pending" }, replace: true  })
      //Do a check if product exists already or else this will not update

      try {
          let res = await fetch(create_api2, {
            mode: 'cors',
            method: "POST",
            headers: {
              'Content-type': 'application/json',
              'Accept': 'application/json',
              'Authorization': props.userInfo.jwt,
              //'Access-Control-Allow-Origin': '*',
            },
            body: JSON.stringify({
              
              //Make a template for this to pass the answers properly
              TableName: "ProductRegistrationDev",
              "Item": {
              "Product": {
                "S": getValues("prd_name")
                },
              "Product_Instance_Site": {
                "S": getValues("prd_inst_url")
              },
              "Product Name": {
                "S": getValues("prd_name")
              },
              "ThirdParty_ProductVendor" : {
                "S": getValues("tp_vendor_name")
              },
              "VendorSite": {
                "S": getValues("tp_vendor_site")
              },
              "PCI": {
                 "S": getValues("pci")
              },
              "PHI": {
                 "S": getValues("phi")
              },
              "PII": {
                 "S": getValues("pii")
              },
              "CCnum": {
                "S": getValues("ccnum")
              },
              "TPSTPTA": {
                "S": getValues("tptanum")
                },
              "Importance": {
                  "S": getValues("importance").value
              },
              "PrimaryOwner": {
                "S": getValues("primary_owner")
              },
              "SecondaryOwner": {
                "S": getValues("secondary_owner")
              },
              "CTI": {
                "S": getValues("category") + getValues("type") + getValues("item")
                },
              "Bindle": {
                "S": getValues("bindle")
              },
              "Notes": {
                "S": getValues("notes")  
              },
              }  
            }),
        });
    
    //make a refresh happen here or something.
    res.json().then(data => {
      console.log(data);
    }).then(() => navigate("/", { state: { submitStatus: "submitted" }, replace: true }));
    
    }  catch (err) {
            console.log(err);
          }


  }        
  

  return(
  
      <div>
      <form onSubmit={handleSubmit(onSubmit)}>
      <Form
          actions={
              <SpaceBetween direction="horizontal" size="xs">
                  <Button onClick={onClickHandler} formAction="none" variant="link">
                      Cancel
                      </Button>
                  <Button variant="primary">Submit</Button>
              </SpaceBetween>
      }>
      <SpaceBetween size="l"> 
      <Container
      header={
          <Header       
              variant="h2">
                  Third-Party Product Information 
          </Header>
      }>

      <SpaceBetween size="l">
      <ColumnLayout columns={2}>
      <FormField
          stretch={true}
          label="Product Name" errorText={errors?.prd_name?.message?.toString()}
          
        >
          <Controller
            name="prd_name"
            defaultValue={""}
            rules={{
              required: "Required field",
              maxLength: {
                value: 20,
                message: "Character limit exceeds 20"
              },
              pattern: {
                value: /^[\w\-\s]+$/,
                message: "Only alphanumeric characters accepted"
              }
            }}
            control={control}
            render={({ field: { onBlur, onChange, value } }) => (
              <Input value={value} onChange={({ detail }) => {
                onChange(detail.value);
              }}
                onBlur={onBlur} />
            )} />
        </FormField>

        <FormField
          stretch={true}
          label="Product Instance Website" errorText={errors?.prd_inst_url?.message?.toString()}>
          <Controller
            name="prd_inst_url"
            rules={{
              required: "Required field",
              pattern: {
                value: /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
                message: "Invalid URL"
              },
            }}
            control={control}
            defaultValue=""
            render={({ field: { onBlur, onChange, value } }) => (
              <Input value={value} onChange={({ detail }) => {
                onChange(detail.value);
              }}
                onBlur={onBlur} />
            )} />
        </FormField>
        </ColumnLayout>


        <ColumnLayout columns={2}>
        <FormField
          stretch={true}
          label="Third Party (Vendor)" errorText={errors?.tp_vendor_name?.message?.toString()}
          
        >
          <Controller
            name="tp_vendor_name"
            defaultValue={""}
            rules={{
              required: "Required field",
              maxLength: {
                value: 20,
                message: "Character limit exceeds 20"
              },
              pattern: {
                value: /^[\w\-\s]+$/,
                message: "Only alphanumeric characters accepted"
              }
            }}
            control={control}
            render={({ field: { onBlur, onChange, value } }) => (
              <Input value={value} onChange={({ detail }) => {
                onChange(detail.value);
              }}
                onBlur={onBlur} />
            )} />
        </FormField>


        <FormField
          stretch={true}
          label="Third Party (Vendor) Website " errorText={errors?.tp_vendor_site?.message?.toString()}
          
        >
          <Controller
            name="tp_vendor_site"
            defaultValue={""}
            rules={{
              required: "Required field",
              pattern: {
                value: /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
                message: "Invalid URL"
              },
            }}
            control={control}
            render={({ field: { onBlur, onChange, value } }) => (
              <Input value={value} onChange={({ detail }) => {
                onChange(detail.value);
              }}
                onBlur={onBlur} />
            )} />
        </FormField>
        </ColumnLayout>

        <FormField label="How important is this system to your team or business unit" errorText={errors?.importance?.message?.toString()}>
        <Controller
          name="importance"
          rules={{ required: "Required field", }}
          control={control}
          defaultValue="medium"
          render={({ field: { onChange, value } }) => (
              <Select
                  selectedOption={value}
                  onChange={({ detail }) => { onChange(detail.selectedOption) }}
                              options={[
                                  { label: "Low", value: "low", description: "Not so important"},
                                  { label: "Medium", value: "medium", description: "Somewhat important" },
                                  { label: "High", value: "high", description: "Very important" },
                                  { label: "Critical", value: "critical", description: "Absolutely necessary" }
                                  ]}
                               />

          )} />

        </FormField>

        <SpaceBetween size="s">
        <FormField label="Does this product process/handle Personal Identifiable Information (PII)?" errorText={errors?.pii?.message?.toString()}>
        <Controller
          name="pii"
          rules={{ required: "Required field", }}
          control={control}
          defaultValue=""
          render={({ field: { onChange, value } }) => (
              <RadioGroup
                  onChange={({ detail }) => onChange(detail.value)}
                  value={value}
                  items={[
                      { value: "Y", label: "Yes"},
                      { value: "N", label: "No"},
                  ]}
              />
          )} />
          

      </FormField>
        <FormField label="Does this product process/handle Protected Health Information (PHI)?" errorText={errors?.phi?.message?.toString()}>
        <Controller
          name="phi"
          rules={{ required: "Required field", }}
          control={control}
          defaultValue=""
          render={({ field: { onChange, value } }) => (
        <RadioGroup
            onChange={({ detail }) => onChange(detail.value)}
            value={value}
            items={[
              { value: "Y", label: "Yes"},
              { value: "N", label: "No"},
            ]}
          />
      )} />

      </FormField>
        <FormField label="Does this product process/handle Payment Card Industry Data (PCI)?" errorText={errors?.pci?.message?.toString()}>
        <Controller
          name="pci"
          rules={{ required: "Required field", }}
          control={control}
          defaultValue=""
          render={({ field: { onChange, value } }) => (
              <RadioGroup
                  onChange={({ detail }) => onChange(detail.value)}
                  value={value}
                  items={[
                      { value: "Y", label: "Yes"},
                      { value: "N", label: "No"},
                  ]}
              />
          )} />
          </FormField>
          </SpaceBetween>
          </SpaceBetween>
      </Container>


      <Container
      header={
          <Header
              variant="h2">
                  Ownership Information
          </Header>
      }>

      <SpaceBetween size="s">
      
      <SpaceBetween size="s" direction="horizontal">
      <FormField
          stretch={true}
          label="Primary Owner's Alias" errorText={errors?.primary_owner?.message?.toString()}
          
        >
          <Controller
            name="primary_owner"
            defaultValue={""}
            rules={{
              required: "Required field",
              maxLength: {
                value: 25,
                message: "Character limit exceeds 25"
              },
              pattern: {
                value: /^\S+@\S+\.\S+$/,
                message: "not an email"
              }
            }}
            control={control}
            render={({ field: { onBlur, onChange, value } }) => (
              <Input value={value} onChange={({ detail }) => {
                onChange(detail.value);
              }}
                onBlur={onBlur} />
            )} />
        </FormField>

        <FormField
          stretch={true}
          label="Secondary Owner's Alias" errorText={errors?.secondary_owner?.message?.toString()}
          
        >
          <Controller
            name="secondary_owner"
            defaultValue={""}
            rules={{
              required: "Required field",
              maxLength: {
                value: 25,
                message: "Character limit exceeds 25"
              },
              pattern: {
                value: /^\S+@\S+\.\S+$/,
                message: "not an email"
              }
            }}
            control={control}
            render={({ field: { onBlur, onChange, value } }) => (
              <Input value={value} onChange={({ detail }) => {
                onChange(detail.value);
              }}
                onBlur={onBlur} />
            )} />
        </FormField>

      </SpaceBetween>

    
      <FormField
          stretch={true}
          label="Bindle" description="Enter the bindle ID you want to associate this record with" errorText={errors?.bindle?.message?.toString()}
          
        >
          <Controller
            name="bindle"
            defaultValue={""}
            rules={{
              required: "Required field",
              maxLength: {
                value: 50,
                message: "Character limit exceeds 25"
              },
              pattern: {
                value: /^amzn1\.bindle\.resource\.[a-zA-Z0-9]{20}$/,
                message: "not in bindle format"
              }
            }}
            control={control}
            render={({ field: { onBlur, onChange, value } }) => (
              <Input value={value} 
              placeholder="amzn1.bindle.resource.xxxxxxxxxxxxxxxxxxxx" 
              onChange={({ detail }) => {
                onChange(detail.value);
              }}
                onBlur={onBlur} />
            )} />
        </FormField>

      


      <FormField label="Categories, Types, Items (CTI)" description="A category, type, and item associated with a ticket for categorization. https://cti.amazon.com/">
        <SpaceBetween size="xs">
        <FormField
          stretch={true}
          errorText={errors?.category?.message?.toString()}
          
        >
          <Controller
            name="category"
            defaultValue={""}
            rules={{
              required: "Required field",
              maxLength: {
                value: 15,
                message: "Character limit exceeds 15"
              },
              pattern: {
                value: /^[\w\-\s]+$/,
                message: "Only alphanumeric characters accepted"
              }
            }}
            control={control}
            render={({ field: { onBlur, onChange, value } }) => (
              <Input value={value} placeholder="Category" onChange={({ detail }) => {
                onChange(detail.value);
              }}
                onBlur={onBlur} />
            )} />
        </FormField>
        <FormField
          stretch={true}
          errorText={errors?.type?.message?.toString()}
          
        >
          <Controller
            name="type"
            defaultValue={""}
            rules={{
              required: "Required field",
              maxLength: {
                value: 15,
                message: "Character limit exceeds 15"
              },
              pattern: {
                value: /^[\w\-\s]+$/,
                message: "Only alphanumeric characters accepted"
              }
            }}
            control={control}
            render={({ field: { onBlur, onChange, value } }) => (
              <Input value={value} placeholder="Type" onChange={({ detail }) => {
                onChange(detail.value);
              }}
                onBlur={onBlur} />
            )} />
        </FormField>
        <FormField
          stretch={true}
          errorText={errors?.item?.message?.toString()}
          
        >
          <Controller
            name="item"
            defaultValue={""}
            rules={{
              required: "Required field",
              maxLength: {
                value: 15,
                message: "Character limit exceeds 15"
              },
              pattern: {
                value: /^[\w\-\s]+$/,
                message: "Only alphanumeric characters accepted"
              }
            }}
            control={control}
            render={({ field: { onBlur, onChange, value } }) => (
              <Input value={value} placeholder="Item" onChange={({ detail }) => {
                onChange(detail.value);
              }}
                onBlur={onBlur} />
            )} />
            </FormField>
            </SpaceBetween>
            </FormField>
          </SpaceBetween>
        </Container>



        <Container
      header={
          <Header
              variant="h2">
                  Additional Information
          </Header>
      }>

  <SpaceBetween size="l"> 

  <FormField
          label="Contract Central Number"
          stretch={true}
          errorText={errors?.ccnum?.message?.toString()}
          
        >
          <Controller
            name="ccnum"
            defaultValue={""}
            rules={{
              maxLength: {
                value: 15,
                message: "Character limit exceeds 15"
              },
              pattern: {
                value: /^[\w\-\s]+$/,
                message: "Only alphanumeric characters accepted"
              }
            }}
            control={control}
            render={({ field: { onBlur, onChange, value } }) => (
              <Input value={value} onChange={({ detail }) => {
                onChange(detail.value);
              }}
                onBlur={onBlur} />
            )} />
        </FormField>
  
        <FormField
          label="Third-Party Security Assessment Number (TPTA)"
          stretch={true}
          errorText={errors?.tptanum?.message?.toString()}
          
        >
          <Controller
            name="tptanum"
            defaultValue={""}
            rules={{
              maxLength: {
                value: 15,
                message: "Character limit exceeds 15"
              },
              pattern: {
                value: /^[\w\-\s]+$/,
                message: "Only alphanumeric characters accepted"
              }
            }}
            control={control}
            render={({ field: { onBlur, onChange, value } }) => (
              <Input value={value} onChange={({ detail }) => {
                onChange(detail.value);
              }}
                onBlur={onBlur} />
            )} />
        </FormField>

  

  


        <FormField
          label="Notes" 
          description="Any additional information about this product instance"
          stretch={true}
          errorText={errors?.notes?.message?.toString()}
          
        >
          <Controller
            name="notes"
            defaultValue={""}
            rules={{
              maxLength: {
                value: 255,
                message: "Character limit exceeds 255"
              },
              
            }}
            control={control}
            render={({ field: { onBlur, onChange, value } }) => (
              <Textarea value={value} onChange={({ detail }) => {
                onChange(detail.value);
              }}
                onBlur={onBlur} />
            )} />
        </FormField>



  </SpaceBetween>

  </Container>





      </SpaceBetween>   
      

      

      
      
      
      
          </Form>
      </form>
  </div>    

    
  );
}