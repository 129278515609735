import * as React from "react";
import Button from "@amzn/awsui-components-react-v3/polaris/button";
import Link from "@amzn/awsui-components-react-v3/polaris/link";
import 'bootstrap/dist/css/bootstrap.css';
import Carousel from 'react-bootstrap/Carousel';
import { useLocation } from 'react-router-dom';
import aboutus from '../Images/TPPS_Portal_About_Us.jpg';
import discovery from '../Images/TPPS_Portal_discovery.jpg';
import prodEval from '../Images/prodEval.png';
import Registration from '../Images/registrationForm.png';
import Governance from '../Images/TPPS_Portal_Policy_governance.jpg';
import Monitoring from '../Images/TPPS_Portal_Continues_Monitoring.jpg';
import Prevention from '../Images/TPPS_Portal_Mitigation_Prevention.jpg';
import discoveryIcon from '../Images/Discovery_icon.png';
import MonitoringIcon from '../Images/ContiniousMonitoring_icon.png';
import PreventionIcon from '../Images/PreventionandMitigation_icon.png';
import Footer from "./Footer";
import { ColumnLayout } from "@cloudscape-design/components";
import TopBar from './TopBar';





export default function MainPage(props: any) {

    // Logic for displaying the success message in the flash bar.
    // This takes the location object containig props when
    // using navigate
    const stat: any = useLocation();
    let status = stat?.state?.submitStatus;
    return (
      
        <div style={{
          backgroundColor: 'offwhite'
        }}>
  <TopBar userInfo={props.userInfo} />
    
                 {/* <div style={{ display: 'block',  height:400 ,padding: 30 }}> */}
                  
                   <Carousel>
                     <Carousel.Item interval={1900}>
                       <img
                         className="d-block w-100"
                         src={aboutus}  height={450}
                         alt="Image One" />
                       <Carousel.Caption>
                         <h1>About us</h1>
                         <p>The TPPS team is responsible for securing 3P cloud products used within Amazon Consumer and driving
transformative changes on how hundreds of Amazon teams secure these products. To achieve this objective,
TPPS has launched a program aimed at facilitating the secure use of 3P cloud products and streamlining business
teams’ journeys. TPPS will define policies and standards, automate 3P cloud product discovery, operationalize
continuous assessments, and implement product-centric remediation guidance and mechanisms for on-going
enforcement. The identification and notification of security issues using a centralized finding management
(Shepherd) system will drive risk reduction. TPPS will provide clear security guidance and mechanisms to enable
38business teams to mitigate security issues. 
<Button variant="primary">Learn More</Button></p>
                       </Carousel.Caption>
                     </Carousel.Item>
                     <Carousel.Item interval={1000}>
                       <img
                         className="d-block w-100"  height={450}
                         src={Governance}
                         alt="Image Two" />
                       <Carousel.Caption>
                         <h1>Program security governance and risk management</h1>
                         <p>Defines security requirements and assessment methodologies for 3P cloud products focusing on the
following key components: 1) Developing an assessment methodology, and 2) Defining security controls and
mapping to policies/standards .<br></br>
<Button variant="primary">Learn More</Button></p>
                       </Carousel.Caption>
                     </Carousel.Item>
                     <Carousel.Item interval={1000}>
                       <img
                         className="d-block w-100"  height={450}
                         src={discovery}
                         alt="Image Two" />
                       <Carousel.Caption>
                         <h1>Continuous discovery of 3P cloud products</h1>
                         <p>The Discovery program is responsible for continuously identifying Amazon’s 3P cloud products (SaaS/PaaS) attack surface, create a view into 3P SaaS/PaaS footprint and provide the necessary insights needed to take a risk prioritized approach to product evaluation. 
                         <br></br><Button variant="primary">Learn More</Button> </p>
                       </Carousel.Caption>
                     </Carousel.Item>
                     <Carousel.Item interval={1000}>
                       <img
                         className="d-block w-100"  height={450}
                         src={Monitoring}
                         alt="Image Two" />
                       <Carousel.Caption>
                         <h1>Continuous security assessments</h1>
                         <p>The continuous security assessment model is composed of two key areas: 1) Automated Scanning Capabilities,
164and 2) Log collection, aggregation, and correlation.<br></br>
 <Button variant="primary">Learn More</Button></p>
                       </Carousel.Caption>
                     </Carousel.Item>
                     <Carousel.Item interval={1000}>
                       <img
                         className="d-block w-100"  height={450}
                         src={Prevention}
                         alt="Image Two" />
                       <Carousel.Caption>
                         <h1>Mitigation and Prevention</h1>
                         <p>The focus of this initiative is to achieve sustainable risk reduction. To do this, TPPS focuses on two key areas: 1)
Mitigation of existing risks by minimizing our attack surface (e.g. removal of unused application), addressing high
or critical severity findings, and introduction of auto remediation mechanisms, and 2) Prevention of known risks
through a combination of secure by default templates, hardening guidelines, and by enabling business teams to
make autonomous high-judgement risk-based decisions when deploying new products. <br></br>
<Button variant="primary">Learn More</Button></p>
                       </Carousel.Caption>
                     </Carousel.Item>
                   </Carousel>  
                   {/* </div> */}
                  

                   <div className="row" style={{padding: 200}}>
<ColumnLayout columns={2}>
    <div>
      <img width={200} height={200}
      src={Registration}
      />
      <Link href="/Product_registration_form"><h2>Product Registration</h2></Link>
    </div>

    <div>
      <img width={200} height={200}
      src={discoveryIcon}
      />
      <Link href="/Discovery_Inventory"><h2>Third-Party Cloud Product Inventory</h2></Link>
    </div>


    <div>
      <img width={200} height={200}
      src={MonitoringIcon}
      />
      <h2>Continuous Monitoring</h2>
    </div>

    <div>
      <img width={200} height={200}
      src={PreventionIcon}
      />
      <h2>Prevention and Mitigation</h2>
    </div>

    <div>
      <img width={200} height={200}
      src={prodEval}
      />
      <Link href="/Product_evaluation_main"><h2>Product Evaluation</h2></Link>
    </div>



</ColumnLayout>
</div>
  
   
<Footer />
</div> 
                   
    );
} 
