import React, { useEffect, useState } from "react";
import { Box, Button, Container, Header, PieChart } from "@amzn/awsui-components-react-v3";
import { scan_api } from "../utils/constants";

export default function DonutChart(props: any) {
    const [total, setTotal] = useState(0);
    const [data, setData] = useState<any[]>([]);
    const [status, setStatus] = useState<any>("loading");
    function countFreq(arr: any, n: number) {
        var mp = new Map();

        // Traverse through array elements and
        // count frequencies
        for (var i = 0; i < n; i++) {
            if (mp.has(arr[i]))
                mp.set(arr[i], mp.get(arr[i]) + 1)
            else
                mp.set(arr[i], 1)
        }
        var keys: any = [];
        mp.forEach((value, key) => {
            let obj: any = {};
            obj["title"] = key;
            obj["value"] = value;
            keys.push(obj);
        });
        setData(keys);
        setStatus("finished");
    }

    function processData(item_arr: any) {
        setTotal(item_arr.length);
        let arr: Array<string> = [];
        for (let i = 0; i < total; i++) {
            if (item_arr[i]['submitter_alias'] === undefined)
                arr.push("no-input");
            else
                arr.push(item_arr[i]['submitter_alias']);
        }
        countFreq(arr, total);
    }
    // getData helper function
    async function getData(jwt: string) {
        let request: any;
        request = {};
        request["action"] = "scan";

        try {
            const response = await fetch(scan_api, {
                mode: 'cors',
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': jwt,
                },
                body: JSON.stringify(request)
            });

            response.json().then(data => {
                processData(data.Items)
            })
        }
        // TODO: Handle the error more gracefully by adding a pop up
        catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        console.log("donut")
        getData(props.userInfo.jwt);
    }, [total])

    return (
        <Container
            header={
                <Header
                    variant="h2"
                    description="A break-up of all registered products with respect to the importance of the application"
                >
                    Product statistics
                </Header>
            }
        >
            <PieChart
                data={data}
                segmentDescription={(datum, sum) =>
                    `${datum.value} entries, ${(
                        (datum.value / sum) *
                        100
                    ).toFixed(0)}%`
                }
                i18nStrings={{
                    detailsValue: "Value",
                    detailsPercentage: "Percentage",
                    filterLabel: "Filter displayed data",
                    filterPlaceholder: "Filter data",
                    filterSelectedAriaLabel: "selected",
                    detailPopoverDismissAriaLabel: "Dismiss",
                    legendAriaLabel: "Legend",
                    chartAriaRoleDescription: "pie chart",
                    segmentAriaRoleDescription: "segment"
                }}
                ariaDescription="Donut chart showing generic example data."
                ariaLabel="Donut chart"
                errorText="Error loading data."
                innerMetricDescription="total entries"
                innerMetricValue={total.toString()}
                loadingText="Loading chart"
                statusType={status}
                recoveryText="Retry"
                size="large"
                variant="donut"
                empty={
                    <Box textAlign="center" color="inherit">
                        <b>No data available</b>
                        <Box variant="p" color="inherit">
                            There is no data available
                        </Box>
                    </Box>
                }
                noMatch={
                    <Box textAlign="center" color="inherit">
                        <b>No matching data</b>
                        <Box variant="p" color="inherit">
                            There is no matching data to display
                        </Box>
                        <Button>Clear filter</Button>
                    </Box>
                }
            />
        </Container>
    );
}