/* eslint-disable import/no-extraneous-dependencies */
import {
    Alert,
    Box,
    Button,
    ColumnLayout,
    Container,
    Header,
    Input,
    Pagination,
    SelectProps,
    SpaceBetween,
    Spinner,
    StatusIndicator,
  } from "@amzn/awsui-components-react-v3";
  import "@amzn/awsui-global-styles/polaris.css";
  import React, { ReactNode, useState } from "react";
  


  import { CellEditorProps,SelectDropDownEditor, DataGrid,SortOrder,Option, EditorContainer, PopoverPlacement,FieldEditorProps,FillEvent, FilterOptions,DataGridPreferences,SelectionType,SelectAllState,ColumnDefinition ,SelectedFilterOptions,CellPopoverProps, DataGridSortAndFilter, CopyEvent, PasteEvent} from '@amzn/polaris-data-grid';
  import {PageSizePreference} from '@amzn/polaris-data-grid/dist/data-grid-preferences/interfaces/PageSizePreferences';
  import {VisibleContentPreference} from '@amzn/polaris-data-grid/dist/data-grid-preferences/interfaces/VisibleContentPreference';
  // import styles from  "./data-grid-stories.module.scss"
  import { Preferences } from '@amzn/polaris-data-grid/dist/data-grid-preferences/interfaces/Preferences';
  import DataGridColumn from "./model/DataGridColumns";
  import { NavigateFunction, useNavigate } from "react-router";
  
 
  import { SelectOptionDefinition } from "@amzn/polaris-data-grid/dist/data-grid-editors/SelectDropDownEditor/interfaces/SelectDropDownEditorProps";
  import {TPPSDiscoveryDataInsert } from "../utils/constants";
  import {TPPSDiscoveryDataInternal } from "../utils/constants";
  enum StatusType {
    success = "SUCCESS",
    loading = "LOADING",
    error = "ERROR",
    draggedOver = "DRAGGED_OVER",
  }
  
  
  interface DataGridDemoArgs {
    rows: DataGridColumn[];
    isLoading?: boolean;
    selectionType?: SelectionType;
    enableSortAndFilter?: boolean;
    dragToFill?: boolean;
    stickyHeader?: boolean;
    jwt_token:string;
  }
 
  
  export const DiscoveryData: React.FC<DataGridDemoArgs> = ({
    rows,
    isLoading = false,
    selectionType = 'multi',
    enableSortAndFilter = true,
    dragToFill = true,
    stickyHeader = false,
    jwt_token,
  }: DataGridDemoArgs): JSX.Element => {

    const navigate: NavigateFunction = useNavigate();

    const [isBusy, setBusy] = useState(true)
    const [entries, setEntries] = useState<DataGridColumn[]>([]);
    const [visible, setVisible] = React.useState(false);
    const [isloading, setIsLoading] = React.useState(false);
   
     
    

    const SelectDropDownEditorContainer = ({
      currentValue,
      onChange,
    }: FieldEditorProps<SelectOptionDefinition>): JSX.Element => {
      const dropdownOptions: SelectProps.Options = [
        {
          value: "Yes",
        },
        {
          value: "No",
        },
      ];
      return (
        <div style={{ minWidth: "29rem" }}>
          <SelectDropDownEditor
            dropDownOptions={dropdownOptions}
            statusType={"finished"}
            currentValue={currentValue}
            onChange={onChange}
          />
        </div>
      );
    };
    
    const loadingMessage = (numValues?: number): JSX.Element => {
      let message = "Saving results";
      if (numValues >= 1) {
        message = `${message}: ${numValues} values`;
      }
    
      return (
        <div style={{ maxWidth: "22rem" }}>
          <Box padding={{ top: "xxs", bottom: "xxs", left: "xs", right: "xs" }}>
            <Spinner size="normal" /> {message}
          </Box>
        </div>
      );
    };
    
    const successMessage = (numValues?: number): JSX.Element => {
      let message = "Successfully saved";
      if (numValues >= 1) {
        message = `${message}: ${numValues} values`;
      }
    
      return (
        <div style={{ maxWidth: "22rem" }}>
          <Box padding={{ top: "xs", bottom: "xs", left: "s", right: "s" }}>
            <StatusIndicator type="success">{message}</StatusIndicator>
          </Box>
        </div>
      );
    };
    
    const failMessage = (
      <div style={{ maxWidth: "22rem" }}>
        <Box padding={{ top: "xs", bottom: "xs", left: "m", right: "xs" }}>
          <ColumnLayout borders="horizontal" columns={1}>
            <StatusIndicator type="error">Failed to save</StatusIndicator>
            <div style={{ color: "#d13212" }}>
              The system failed to save this edit. Please try again.
            </div>
          </ColumnLayout>
        </Box>
      </div>
    );
    
    const getStatusMessageToDisplay = (
      statusType?: StatusType,
      numValues?: number
    ): JSX.Element | undefined => {
      switch (statusType) {
        case StatusType.loading:
          return loadingMessage(numValues);
        case StatusType.success:
          return successMessage(numValues);
        case StatusType.error:
          return failMessage;
        default:
          return undefined;
      }
    };
    
    const getStatusBoxForDragToFill = (
      statusType?: StatusType,
      numValues?: number
    ): JSX.Element | undefined => {
      return (
        <Container disableContentPaddings disableHeaderPaddings>
          {getStatusMessageToDisplay(statusType, numValues)}
        </Container>
      );
    };
    
    const InlineTextEditorContainer = ({
      row,
      column,
      onRowChange,
      onClose
    }: CellEditorProps<DataGridColumn>): JSX.Element => {
      const key = column.id;
      const [value, setValue] = React.useState<string>(row[key]);
      return (
      <Box padding={{top: "xxs"}}>
        <Input
          value={value}
          onChange={({ detail }) => {
            setValue(detail.value);
          }}
          onKeyDown={({ detail }) => {
            if (detail.keyCode === 13) {
              row[key] = value;
              onRowChange(row);
              updatedCells[row.instance_url] = updatedCells[row.instance_url] || [];
              updatedCells[row.instance_url][key] = StatusType.success;
              onClose(true);
            }
          }}
        />
      </Box>);
    }
    
  
    
    const DropdownEditorContainer = (props: CellEditorProps<DataGridColumn>): JSX.Element => {
      const [status, setStatus] = React.useState<StatusType | undefined>();
      return (
        <EditorContainer<DataGridColumn, SelectOptionDefinition>
          editorProps={props}
          editor={
            status === undefined || status === StatusType.error
              ? SelectDropDownEditorContainer
              : EmptyEditorComponent
          }
          statusBox={getStatusMessageToDisplay(status)}
          onValueChange={(row: DataGridColumn, key: string, value: unknown) => {
            const updatedValue = (value as SelectOptionDefinition).option.value;
            setStatus(StatusType.loading);
            row[key] = updatedValue;
            props.onRowChange(row);
            setTimeout(() => {
              updatedCells[row.instance_url] = updatedCells[row.instance_url] || [];
              setStatus(updatedValue === "N/A" ? StatusType.error : StatusType.success);
              if (updatedValue !== "N/A") {
                updatedCells[row.instance_url][key] = StatusType.success;
                setTimeout(() => {
                  props.onClose(true);
                }, 2000);
              } else {
                updatedCells[row.instance_url][key] = StatusType.error;
              }
              props.onRowChange(row);
            }, 3000);
          }}
        />
      );
    };
    
    const columns: ColumnDefinition<DataGridColumn>[] = [
    //   {
    //   id: "instance_url",
    //   label: "id",
    //   // cell: (e: DataGridColumn) => e.instance_url,
    //   cell: (row: DataGridColumn): ReactNode => cellRenderer(row, "instance_url"),
    //   // cellContainerClass: (): string => styles.disabledCell,
    //   minWidth: 100,
    //   editable: () => {
    //     return false;
    //   },
    // },
  {
    id: "vendor_name",
    label: "Vendor Name",
    // cell: (e: DataGridColumn) => e.vendor_name,
    cell: (row: DataGridColumn): ReactNode => cellRenderer(row, "vendor_name"),
    minWidth: 150,
    editable:  () => true,
    editor: InlineTextEditorContainer,
    editorOptions: { editOnDoubleClick: true, renderWithoutCellContent: true },
  },
  {
    id: "vendor_url",
    label: "Vendor Website",
    // cell: (e: DataGridColumn) => e.vendor_url,
    cell: (row: DataGridColumn): ReactNode => cellRenderer(row, "vendor_url"),
    minWidth: 100,
    editable:  () => true,
    editor: InlineTextEditorContainer,
    editorOptions: { editOnDoubleClick: true, renderWithoutCellContent: true },
  },
  {
    id: "product",
    label: "Product Name",
    // cell: (e: DataGridColumn) => e.product_name,
    cell: (row: DataGridColumn): ReactNode => cellRenderer(row, "product"),
    minWidth: 100,
    editable: () => true,
    editor: InlineTextEditorContainer,
    editorOptions: { editOnDoubleClick: true, renderWithoutCellContent: true },
  },
    {
    id: "product_type",
    label: "Product Type",
    // cell: (e: DataGridColumn) => e.data_source,
    cell: (row: DataGridColumn): ReactNode => cellRenderer(row, "product_type"),
    minWidth: 100,
    editable:  () => true,
    editor: InlineTextEditorContainer,
    editorOptions: { editOnDoubleClick: true, renderWithoutCellContent: true },
  },
  {
    id: "instance_url",
    label: "Instance Url",
    cell: (row: DataGridColumn): ReactNode => cellRenderer(row, "instance_url"),
    // cell: (e: DataGridColumn) => e.instance_url_or_domain,
    minWidth: 100,
    editable: () => false
  },

  {
    id: "is_externally_accessible",
    label: "Externally Accessible",
    // cell: (e: DataGridColumn) => e.is_externally_accessible,
    cell: (row: DataGridColumn): ReactNode => cellRenderer(row, "is_externally_accessible"),
    minWidth: 100,
    editable: () => true,
    editor: DropdownEditorContainer,
    editorOptions: { editOnDoubleClick: true, renderWithoutCellContent: true },
  },
  {
    id: "is_open_source",
    label: "Open Source",
    // cell: (e: DataGridColumn) => e.open_source,
    cell: (row: DataGridColumn): ReactNode => cellRenderer(row, "is_open_source"),
    minWidth: 100,
    editable: () => true,
    editor: DropdownEditorContainer,
    editorOptions: { editOnDoubleClick: true, renderWithoutCellContent: true },
  },
  {
    id: "is_amazon_developed",
    label: "Amazon Developed",
    // cell: (e: DataGridColumn) => e.amazon_developed,
    cell: (row: DataGridColumn): ReactNode => cellRenderer(row, "is_amazon_developed"),
    minWidth: 100,
    editable: () => true,
    editor: DropdownEditorContainer,
    editorOptions: { editOnDoubleClick: true, renderWithoutCellContent: true },
  },
  
  // {
  //   id: "data_source",
  //   label: "Data Source",
  //   // cell: (e: DataGridColumn) => e.data_source,
  //   cell: (row: DataGridColumn): ReactNode => cellRenderer(row, "data_source"),
  //   minWidth: 100,
  //   editable: () => false
  // }
    ];
    
    const [preferences, setPreferences] = React.useState({
      pageSize: 10,
      visibleContent: columns.map((column) => column.id),
      filteredContent: [],
    } as Preferences);
  
    const [visibleRows, setVisibleRows] = React.useState<DataGridColumn[]>([]);
  
    const [currentPageIndex, setCurrentPageIndex] = React.useState(1);
  
    const [currentSortProperty, setCurrentSortProperty] = React.useState<string>();
  
    const [currentSortOrder, setCurrentSortOrder] = React.useState<SortOrder>(SortOrder.NONE);
  
    const [filteredRows, setFilteredRows] = React.useState(rows);
  
    const [pageCount, setPageCount] = React.useState(
      Math.ceil(filteredRows.length / preferences.pageSize)
    );
  
    const [filters] = React.useState(new Map<string, SelectedFilterOptions>());
  
    const [cellPopoverProps, setCellPopoverProps] = React.useState<CellPopoverProps>();
  
    const EmptyEditorComponent = ({}) => <></>;
  

  
    
    const getColumnIndexFromColumnKey = (row: DataGridColumn, key: string): number => {
      const rowArray = Object.entries(row);
      return rowArray.findIndex((prop) => prop[0] === key);
    };
    
    const onCopy = ({
      sourceRow,
      sourceColumnKey
    }: CopyEvent<DataGridColumn>) => {
      if (window.isSecureContext) {
        navigator.clipboard.writeText(sourceRow[sourceColumnKey]);
      }
    };
    
    
    const updatedCells = {};
    
    // Randomly enable/disable rows
    const isEditable = (row: DataGridColumn): boolean => {
      return !!(row.is_validated);
    };
    
    // const getCellClass = (row: Book, property: string): string => {
    //   if (!isEditable(row)) {
    //     return styles.disabledCell;
    //   }
    //   if (updatedCells[row.id] && updatedCells[row.id][property] === StatusType.draggedOver) {
    //     return styles.draggedOverCell;
    //   }
    //   if (updatedCells[row.id] && updatedCells[row.id][property] === StatusType.success) {
    //     return styles.savedCell;
    //   }
    //   if (updatedCells[row.id] && updatedCells[row.id][property] === StatusType.error) {
    //     return styles.errorCell;
    //   }
    //   return "";
    // };
    
    const cellRenderer = (row: DataGridColumn, property: string): string => {
      return row && row[property] ? row[property] : "-";
    };
    
    
    const sampleVisibleContentPreference: VisibleContentPreference = {
      title: "Select visible columns",
      description:
        "If there is a filter applied to a column, deselecting the visibility of the column will remove the filter in the Headcount details table.",
      optionsGroup: {
        label: "Columns",
        options: columns.map((column) => ({
          id: column.id,
          label: column.label,
        })),
      },
      removeFilterWhenHidden: true,
    };
    
    const samplePageSizePreference: PageSizePreference = {
      title: "Page size",
      options: [
        { value: 10, label: "10 items" },
        { value: 20, label: "20 items" },
        { value: 30, label: "30 items" }
      ],
    };
    
    function sort(sortProperty: string, sortOrder: SortOrder, data: DataGridColumn[]) {
      const sort = sortOrder === SortOrder.ASC ? 1 : -1;
      const sortedRows = [...data].sort((a, b) => {
        return a[sortProperty] > b[sortProperty] ? sort : -sort;
      });
      return sortedRows;
    }
    
    function filter(rows: DataGridColumn[], filters: Map<string, SelectedFilterOptions>) {
      return rows.filter((row) => {
        let result = true;
        filters.forEach((val, key) => {
          if (val.selectAllState === SelectAllState.NONE) {
            result = false;
            return;
          } else if (val.selectAllState === SelectAllState.ALL) {
            return;
          } else if (val.in) {
            result = result && val.options.map((opt) => opt.value).includes(row[key]);
          } else {
            result = result && !val.options.map((opt) => opt.value).includes(row[key]);
          }
          if (!result) {
            return;
          }
        });
        return result;
      });
    }
    
    interface ColumnHeaderProps<T> {
      rows: DataGridColumn[];
      column: ColumnDefinition<T>;
      currentSortProperty: string;
      currentSortOrder: SortOrder;
      selectedFilterOptions: SelectedFilterOptions;
      onConfirm: (
        selectedSortOrder: SortOrder,
        sortProperty: string,
        selectedFilterOptions: SelectedFilterOptions,
        filterProperty: string
      ) => void;
    }
    
    const ColumnHeader = ({
      rows,
      currentSortProperty,
      currentSortOrder,
      selectedFilterOptions,
      column,
      onConfirm,
    }: ColumnHeaderProps<DataGridColumn>): JSX.Element => {
      const filterOptions: Option[] = [];
      new Set(rows.map((DataGridColumn) => DataGridColumn[column.id])).forEach((opt) => {
        filterOptions.push({
          label: opt,
          value: opt,
        });
      });
      const [currentFilterOptions, setCurrentFilterOptions] = useState<FilterOptions>({
        isPartial: false,
        options: filterOptions.slice(0, filterOptions.length).filter(q => !!q),
      });
      
      const [filterText, setFilterText] = useState<string>();
    
      const [isVisible, setIsVisible] = useState<boolean>(false);
      

      
    
      return (
        <div style={{ display: "block" }}>
          {column.label}
          <div style={{ position: "absolute", right: "15px", top: "0px" }}>
            <DataGridSortAndFilter
              sortProperty={column.id}
              filterProperty={column.id}
              sortHeader={`Sort ${column.label}`}
              filterHeader={`Filter ${column.label}`}
              filterOptions={currentFilterOptions}
              selectedSortOrder={currentSortProperty === column.id ? currentSortOrder : SortOrder.NONE}
              selectedFilterOptions={selectedFilterOptions}
              isLoadingOptions={false}
              emptyOptions="No value found"
              confirmLabel="Apply"
              cancelLabel="Cancel"
              filterText={filterText}
              onOpen={() => {
                setIsVisible(true);
              } }
              onClose={() => {
                setIsVisible(false);
              } }
              onFilterTextChange={(_text: string) => {
                console.log(currentFilterOptions)
                setFilterText(_text);
                setCurrentFilterOptions({
                  isPartial: false,
                  options: filterOptions.filter(q => !!q)
                    .filter((option) => (option.label as string).toLocaleLowerCase().startsWith(_text.toLocaleLowerCase())
                    )
                    .slice(0,filterOptions.length),
                });
              } }
              onDelayedFilterTextChange={(_text: string) => { } }
              onConfirm={onConfirm}
              onCancel={() => { } }
              positionAt={["bottom center", "bottom right", "bottom left"]}
              isSortEnabled={true}
              isFilterEnabled={true}
              isVisible={isVisible} keepComponentInside={true}        />
          </div>
        </div>
      );
    };
    

    const handleFill = ({ updatedRows, sourceRow, columnKey }: FillEvent<DataGridColumn>): void => {
      const visibleRowMap = visibleRows.reduce((map, row) => {
        map.set(row.instance_url, { ...row });
        return map;
      }, new Map());
      updatedRows.forEach((row) => visibleRowMap.set(row.instance_url, { ...row }));
      // Calculate coordinates for status box placement
      const sourceRowIdx = visibleRows.findIndex((row) => row.instance_url === sourceRow.instance_url);
      let rowIdx = visibleRows.findIndex((row) => row.instance_url === updatedRows[updatedRows.length - 1].instance_url);
      const columnIdx = getColumnIndexFromColumnKey(sourceRow, columnKey);
      const dragDirectionIsDown = rowIdx > sourceRowIdx;
      if (!dragDirectionIsDown) {
        rowIdx = rowIdx - updatedRows.length + 1;
      }
      const updatedCellPopoverProps: CellPopoverProps = {
        rowIdx: rowIdx + 1, // add one for header rowx.
        columnIdx: columnIdx + 1, // add one for checkbox column
        placement: dragDirectionIsDown ? PopoverPlacement.below : PopoverPlacement.right,
        content: getStatusBoxForDragToFill(StatusType.loading, updatedRows.length),
        isVisible: true,
      };
      setCellPopoverProps(updatedCellPopoverProps);
      setVisibleRows([...visibleRowMap.values()]);
      updatedRows.forEach((row) => {
        updatedCells[row.instance_url] = updatedCells[row.instance_url] || [];
        updatedCells[row.instance_url][columnKey] = StatusType.draggedOver;
      });
      setTimeout(() => {
        updatedRows.forEach((row) => (updatedCells[row.instance_url][columnKey] = StatusType.success));
        setCellPopoverProps({
          ...updatedCellPopoverProps,
          content: getStatusBoxForDragToFill(StatusType.success, updatedRows.length),
        });
        setTimeout(() => {
          setCellPopoverProps(undefined);
        }, 2000);
      }, 1500);
    };
  
    const onPaste = ({
      sourceRow,
      sourceColumnKey,
      targetRow,
      targetColumnKey,
    }: PasteEvent<DataGridColumn>): DataGridColumn => {
      if (sourceColumnKey === targetColumnKey) {
        targetRow[targetColumnKey] = sourceRow[sourceColumnKey];
        handleFill({
          updatedRows: [targetRow],
          sourceRow,
          columnKey: sourceColumnKey
        });
      }
      return targetRow;
    }
  
    const sortAndFilterListener = (
      sortOrder: SortOrder,
      sortProperty: string,
      selectedFilters: SelectedFilterOptions,
      filterProperty: string
    ) => {
      let newFilteredRows = filteredRows;
      if (filters.get(filterProperty) !== selectedFilters) {
        if (selectedFilters.selectAllState === SelectAllState.ALL && filters.has(filterProperty)) {
          const colIndex = preferences.filteredContent.indexOf(filterProperty);
          const newFilteredContent = [...preferences.filteredContent];
          newFilteredContent.splice(colIndex, 1);
          preferences.filteredContent = newFilteredContent;
        } else {
          preferences.filteredContent = [...preferences.filteredContent, filterProperty];
        }
        filters.set(filterProperty, selectedFilters);
        newFilteredRows = filter(rows, filters);
      }
      if (sortOrder !== SortOrder.NONE) {
        setCurrentSortOrder(sortOrder);
        setCurrentSortProperty(sortProperty);
        newFilteredRows = sort(sortProperty, sortOrder, newFilteredRows);
      }
  
      setFilteredRows(newFilteredRows);
    };
  
    const updatePreferences = (newPreferences: Preferences) => {
      const hiddenColumns = columns
        .map((col) => col.id)
        .filter((col) => !newPreferences.visibleContent.includes(col));
      hiddenColumns.forEach((col) =>
        filters.set(col, {
          in: false,
          options: [],
          selectAllState: SelectAllState.ALL,
        })
      );
      setPreferences(newPreferences);
      setFilteredRows(filter(rows, filters));
    };
  
    React.useEffect(() => {
      if (currentPageIndex > pageCount) {
        return;
      }
      const startIdx = (currentPageIndex - 1) * preferences.pageSize;
      const endIdx = Math.min(startIdx + preferences.pageSize, filteredRows.length);
      if (filteredRows.length <= startIdx) {
        setCurrentPageIndex(1);
      } else {
        setVisibleRows(filteredRows.slice(startIdx, endIdx));
      }
      setPageCount(Math.ceil(filteredRows.length / preferences.pageSize));
    }, [
      setVisibleRows,
      setCurrentPageIndex,
      setPageCount,
      currentPageIndex,
      preferences.pageSize,
      filteredRows,
    ]);
    async function getData(jwt: string) {
      let Data: any;
      Data = {};
      let request: any;
      request = {}
      request["action"] = "getinternal";

  
      try {
        
        const response = await fetch(TPPSDiscoveryDataInternal, {
          mode: 'cors',
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            'Authorization': jwt,
          },
          body: JSON.stringify(request)
        });
        // console.log(response)
        response.json().then(data => {
        // setEntries(JSON.parse(data));
        // this.setState({rows:data})
        // setEntries(data);
        setVisibleRows(data)
        setVisible (false)
        })
      }
      // TODO: Handle the error more gracefully by adding a pop up
      catch (err) {
        console.log(err);
      }
    }
    function sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
    async function onSubmit() {

      let request: any;
       const submit = visibleRows.filter(visibleRow =>selectedRows.has(visibleRow.instance_url))
      // console.log(submit)
      request = {};
      request["action"] = "Insert";
   
  

      const submitCountr = await Promise.all(
        submit.map(async (submitItem) => {
          request["payload"] = submitItem;
          console.log(submitItem)
          const response = await fetch(TPPSDiscoveryDataInsert, {
            mode: 'cors',
            method: 'POST',
            headers: {
              'Content-type': 'application/json',
              'Accept': 'application/json',
              'Authorization': jwt_token,
            },
            body: JSON.stringify(request)
          });
          return await response.json();
        })
      );
          
      console.log(submitCountr[0], submitCountr[1]);
      setFilteredRows(filteredRows.filter(visibleRow =>!selectedRows.has(visibleRow.instance_url)))
      // rows = rows.map(updatedRows => filteredRows.filter(visibleRow =>!selectedRows.has(visibleRow.instance_url)))
      // setFilteredRows(rows)
      setVisible (true);
      await sleep(5000);
     
      setVisible (false);
      // window.location.reload();
      // getData(jwt_token)
















 
      // try {
       
      //   const response = await fetch(TPPSDiscoveryDataInsert, {
      //     mode: 'cors',
      //     method: 'POST',
      //     headers: {
      //       'Content-type': 'application/json',
      //       'Accept': 'application/json',
      //       'Authorization': jwt_token,
      //     },
      //     body: JSON.stringify(request)
      //   });
      //   // TODO: use react-hook-forms states instead of navigating on 'then'
      //   // this is a BUG, where it shows success message on faliure
      //   response.json().then(data => {
      //     console.log(data);
      //   }).then(() =>  setFilteredRows(filteredRows.filter(filteredRows =>!selectedRows.has(filteredRows.instance_url))));

      //   setVisible(true);
      // }
      // // TODO: Handle the error more gracefully by adding a pop up
      // catch (err) {
      //   console.log(err);
      // }
    }
    function onClickHandler() {
     
      // console.log(visibleRows)
      // console.log(updatedCells)
      // console.log(visibleRows["46c7ecd30399b98c95c7ed8d24824161"])
      // const result = messages.filter(message => !message['_id'].match(pattern));
     

      // console.log(selectedRows.has(visibleRows['instance_url']))

      //  validations.forEach(validation => {
      //   const found = stateValidations.find(item => item.message === validation.message 
      //     && item.fieldId === validation.fieldId);
      
      //   if (!found) {
      //     // If not found, add validation.
      //     stateValidations.push(validation);
      //   }
      // });
      
      // console.log(JSON.stringify(stateValidations));
    }
 
  
    var isItemDisabled = (dataGridColumn) => dataGridColumn.instance_url.length >= 1000   
    const [selectedRows, setSelectedRows] = React.useState(new Set<React.Key>());
  
    return (
      <div style={{width :1600,height:1200}}>
                   <Alert
        onDismiss={() => setVisible(false)}
        visible={visible}
        dismissAriaLabel="Close alert"
        header="Alert"
      >
        Your entries are saved and currently being processed , Kindly do not reload/refresh the page for couple of minutes 
      </Alert>
          <DataGrid<DataGridColumn>
            items={visibleRows}
            columnDefinitions={columns}
            header={<Header counter={"(" + filteredRows.length + ")"}>Discovery Details</Header>}
            preferences={
              <DataGridPreferences
                title="Preferences"
                confirmLabel="Confirm"
                cancelLabel="Cancel"
                preferences={preferences}
                onConfirm={updatePreferences}
                pageSizePreference={samplePageSizePreference}
                visibleContentPreference={sampleVisibleContentPreference}
                disabled={isLoading}
              />
            }
            pagination={
              <Pagination
                ariaLabels={{
                  nextPageLabel: "Next page",
                  previousPageLabel: "Previous page",
                  pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
                }}
                currentPageIndex={currentPageIndex}
                onChange={({ detail }) => setCurrentPageIndex(detail.currentPageIndex)}
                openEnd
                pagesCount={pageCount}
                disabled={isLoading}
              />
            }
            
          
            visibleColumns={preferences.visibleContent}
            columnHeaderRenderer={
              enableSortAndFilter
                ? (column) => {
                    return ColumnHeader({
                      rows,
                      column,
                      currentSortProperty: currentSortProperty,
                      currentSortOrder: currentSortOrder,
                      selectedFilterOptions: filters.get(column.id),
                      onConfirm: sortAndFilterListener,
                    });
                  }
                : null
            }
            rowKeyProvider={(row) => row.instance_url}
            selectedItems={selectedRows}
            isItemDisabled={isItemDisabled}
            onSelectionChange={setSelectedRows}
            onFill={dragToFill ? handleFill : null}
            onCopy={onCopy}
            onPaste={onPaste}
            cellPopoverProps={cellPopoverProps}
            selectionType={selectionType}
            empty="No resources to display"
            loading={isLoading}
            stickyHeader={stickyHeader}
            resizableColumns
          />

          
         <SpaceBetween direction="horizontal" size="xs">
            <Button variant="primary" onClick={onSubmit} >Submit</Button>
            {/* <button onClick={() => window.location.reload()}>Click to reload!</button> */}
          </SpaceBetween>
 
          </div>
           

    );
          }