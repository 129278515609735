import * as React from "react";
import {
    Button,
    Header, 
    SpaceBetween,
} from "@amzn/awsui-components-react-v3";
import { NavigateFunction, useNavigate } from "react-router-dom";

export default function MainPageHeader () {
  
  let navigate: NavigateFunction = useNavigate();
  
  const handleClick = (event: any) => {
    event.preventDefault();
    navigate("register")
  }

  return (
    <Header
      variant="h1"
      description="Manage the registration made from the table below"
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Button variant="primary" onClick={handleClick}>
            New Registration
          </Button>
        </SpaceBetween>
      }
    >
      Manage Registrations
    </Header>
  );
}