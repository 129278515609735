import {
    Header,
    AppLayout,
    ContentLayout,
  } from "@cloudscape-design/components";
import TopBar from '../../TopBar';
import ProductEvalSimple from "./ProductEvalSimple";


export default function ProductEvalMain (props: any){

    return(
        
        <div>
            <TopBar userInfo={props.userInfo} />
            <AppLayout
                    navigationHide={true}
                    toolsHide={true}
                    contentType="form"
                    content={
                        <ContentLayout
                            header={
                                <Header
                                    variant="h1">
                      Product Evaluation
                                </Header>
              }>

      <ProductEvalSimple userInfo={props.userInfo} />  
      </ContentLayout>
      }
      />

        </div>
    );

}