import React from 'react';
import TopBar from './TopBar';
import MainTable from './MainTable'
import MainPageHeader from './MainPageHeader'
import StatusBar from './StatusBar'
import { AppLayout, SpaceBetween } from '@amzn/awsui-components-react-v3';
import { useLocation } from 'react-router-dom';
import DonutChart from './DonutChart'

export default function MainPage(props: any) {

    // Logic for displaying the success message in the flash bar.
    // This takes the location object containig props when
    // using navigate
    const stat: any = useLocation();
    let status = stat?.state?.submitStatus;
    return (
        <>
            <TopBar userInfo={props.userInfo} />
            <AppLayout
                contentType="form"
                contentHeader={<MainPageHeader />}
                notifications={status ? <StatusBar submitStatus={status} /> : null}
                content={
                <SpaceBetween size='l'>
                    <MainTable userInfo={props.userInfo} />
                    <DonutChart userInfo={props.userInfo} />
                </SpaceBetween>
                }
                navigationHide={true}
                toolsHide={true} />
        </>
    );
}