import {
  Flashbar,
  FlashbarProps,
} from "@amzn/awsui-components-react-v3";
import {
  useEffect,
  useState
} from "react";

export default function StatusBar(props: any) {
  const [items, setItems] = useState<ReadonlyArray<FlashbarProps.MessageDefinition>>([]);
  const selectType = () => {
    if (props.submitStatus === "pending") {
      setItems([
        {
          type: "success",
          loading: true,
          content:
            "Submitting the product registration form",
          id: "message_1"
        }
      ]);
    }
    if (props.submitStatus === "submitted") {
      setItems([
        {
          type: "success",
          content: "Product registration form successfully submitted.",
          dismissible: true,
          dismissLabel: "Dismiss message",
          onDismiss: () => { props.submitStatus = null; setItems([]); },
          id: "message_1"
        }
      ]);
    }
  }
  useEffect(() => {
    selectType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  return items ? <Flashbar items={items} /> : null;
}