import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";

import './App.css';
import LandingPage from './components/LandingPage';
import MainPage from './components/MainPage';
import DiscoveryInventory from './components/DiscoveryInventory';
import SimpleRegistration from "./components/form_components/simple_product_inst_reg_components/SimpleRegistration";
import ProductEvalMain from './components/form_components/product_eval_components/ProductEvalMain';
import ProductEvalSimple from './components/form_components/product_eval_components/ProductEvalSimple';
import "@amzn/polaris-data-grid/dist/main.css";

export default function App(props: any) {

  return (
    <BrowserRouter>
      <Routes>
      { <Route path="/" element={
          <LandingPage userInfo={props.userInfo}/>
        } /> }
         <Route path="/Discovery_Inventory" element={
          <DiscoveryInventory userInfo={props.userInfo} />
        } /> 
        <Route path="/Product_registration_landingPage" element={
          <MainPage userInfo={props.userInfo} />
        } /> 
        <Route path="/Product_registration_form" element={
          <SimpleRegistration userInfo={props.userInfo} />
        } />
        <Route path="/Product_evaluation_main" element={
          <ProductEvalMain userInfo={props.userInfo} />
        } />
        <Route path="/Product_evaluation" element={
          <ProductEvalSimple userInfo={props.userInfo} />
        } />
      </Routes>
    </BrowserRouter>
  );
}
