import {
  Container,
  FormField,
  Form,
  Header,
  SpaceBetween,
  Button,
  RadioGroup,
  Input,
  ColumnLayout
} from "@amzn/awsui-components-react";
import { v4 as uuidv4 } from 'uuid'; 
import TopBar from '../../TopBar';
import DatePicker from "react-datepicker";
import React, {useState} from "react";
import {post_product_eval} from '../../../utils/constants'
import "react-datepicker/dist/react-datepicker.css";
import { NavigateFunction, useNavigate } from "react-router";
import { Textarea } from "@cloudscape-design/components";






export default function ProductEvalSimple(props:any){


  const [ThirdPartyVendorName, setThirdPartyVendorName] = useState("");
  const [product, setProduct] = useState("");
  const [productURL, setProductUrl] = useState("");
  const [vendorSite, setVendorSite] = useState("");
  //make datepicker
  var [evalStartDate, setEvalStartDate] = useState(new Date());
  
  //make datepicker
  var [evalEndDate, setEvalEndDate] = useState(new Date());

  //
  const [daysToClose, setDaysToClose] = useState("");
  
  
  const [disposition, setDisposition] = useState("");
  const [product_type, setProduct_Type] = useState("");
  const [parent_product, setParent_Product] = useState("");
  const [simTicket, setSimTicket] = useState("");
  const [notes, setNotes] = useState("");
  const [req, setReq] = useState("");

  


  const navigate: NavigateFunction = useNavigate();

  let handleSubmit = async (e) => {

    if (evalEndDate == null) {
       evalEndDate = new Date(100000000000); 
       console.log(evalEndDate)
    } else {
      var newEvalEndDate = evalEndDate.getMonth() + '/' + evalEndDate.getDay() + '/' + evalEndDate.getFullYear()
    }

    if (evalStartDate == null) {
      evalStartDate = new Date(100000000000); 
      console.log(evalStartDate)
   } else {
    var newEvalStartDate = evalStartDate.getMonth() + '/' + evalStartDate.getDay() + '/' + evalStartDate.getFullYear()
   }

      e.preventDefault();
      try {
        let res = await fetch(post_product_eval, {
          mode: 'cors',
          method: "POST",
          headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            'Authorization': props.userInfo.jwt,
            //'Access-Control-Allow-Origin': '*',
          },
          body: JSON.stringify({
            
            //Make a template for this to pass the answers properly
            TableName: "ProductEvalStats",
            "Item": {
            "ProductEvalStatsId": {
              "S": product + "-" + uuidv4()
              },
            "product_name": {
              "S": product
              },
            "product_URL": {
              "S": productURL
              },
            "third_party_name": {
              "S": ThirdPartyVendorName
              },
            "third_party_URL": {
              "S": vendorSite
              },
            "eval_start_date": {
              "S": newEvalStartDate
              },
            "eval_end_date": {
              "S": newEvalEndDate
              },
            "days_to_close": {
              "N": daysToClose
            },
            "disposition": {
              "S": disposition
            },
            "product_type": {
              "S": product_type
            },
            "parent_product": {
              "S": parent_product
            },
            "SIM_ticket": {
              "S": simTicket
            },
            "Req": {
              "S": req
            },
            "Notes": {
              "S": notes
            }
            }  
          }),
      });

  //make a refresh happen here or something.
  res.json().then(data => {
    console.log(data);
  }).then(() => navigate("/", { state: { submitStatus: "submitted" }, replace: true }));
  

  }  catch (err) {
          console.log(err);
        }

  };

  return(

    
    <div style={{
      backgroundColor: 'offwhite'
    }}>

    

      <Container
      header={
          <Header
              variant="h2">
              Product Evaluation 
          </Header>
    }>

      <Form
          actions={
              <SpaceBetween direction="horizontal" size="xs">
                  <Button variant="link">Cancel</Button>
                  <Button variant="primary" onClick={handleSubmit}>Submit</Button>
              </SpaceBetween>
      }>  

      <SpaceBetween size="l"> 

      <ColumnLayout columns={2}>
      
      <FormField label="Product Name" description="Enter the product name">
              <Input value={product} onChange={(event) => setProduct(event.detail.value)} />
      </FormField>

      <FormField label="Product URL" description="Enter the product URL">
              <Input value={productURL} onChange={(event) => setProductUrl(event.detail.value)} />
      </FormField>

      <FormField label="Third Party Name (vendor)" description="Enter the third party name">
              <Input value={ThirdPartyVendorName} onChange={(event) => setThirdPartyVendorName(event.detail.value)} />
      </FormField>
      
      <FormField label="Third Party URL (vendor)" description="Enter related URL">
              <Input value={vendorSite} onChange={(event) => setVendorSite(event.detail.value)} />
      </FormField>

      </ColumnLayout>
      
      <FormField label="Evaluation Start Date" description="">
      <DatePicker dateFormat="MM/dd/yyyy" selected={evalStartDate} onChange={(date) => setEvalStartDate(date)} />
      </FormField>

      <FormField label="Evaluation End Date" description="">
      <DatePicker dateFormat="MM/dd/yyyy" selected={evalEndDate} onChange={(date) => setEvalEndDate(date)} />
      </FormField>

      
      <FormField label="Days to Close" description="" >
        <Input value={daysToClose} onChange={(event) => setDaysToClose(event.detail.value)} />
      </FormField>


      <FormField label="Disposition" description="Has the Security Bar been met?">
      
          <RadioGroup
          onChange={({ detail }) => setDisposition(detail.value)}
          value={disposition}
          
          items={[
            { value: "Not Started", label: "Not Started"},
            { value: "In Progress", label: "In Progress"},
            { value: "Met Security Bar", label: "Met Security Bar" },
            { value: "Did not meet Security Bar", label: "Did not meet Security Bar" }
          ]} />
      
      </FormField>

      <FormField label="Product Type" description="Enter the product type">
              <Input value={product_type} onChange={(event) => setProduct_Type(event.detail.value)} />
      </FormField>

      <FormField label="Parent Product" description="Enter the parent product">
              <Input value={parent_product} onChange={(event) => setParent_Product(event.detail.value)} />
      </FormField>

      <FormField label="SIM Ticket" description="Enter the SIM Ticket URL">
              <Input value={simTicket} onChange={(event) => setSimTicket(event.detail.value)} />
      </FormField>

      <FormField label="Requested" description="Is this a customer requested evaluation">
          <RadioGroup
            onChange={({ detail }) => setReq(detail.value)}
              value={req}        
          items={[
            { value: "Y", label: "Yes"},
            { value: "N", label: "No"},
          ]} />

      </FormField>

      <FormField label="Notes" description="Additional notes about this evaluation">
              <Textarea value={notes} onChange={(event) => setNotes(event.detail.value)} />
      </FormField>

      </SpaceBetween>

      </Form>

  </Container>

  </div>

  );

}