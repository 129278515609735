import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import App from './App';
import Error from './components/Error'
import AuthProvider from './services/cognito-auth';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

AuthProvider().then((userInfo: any) => {
  root.render(
    <React.StrictMode>
      <div className="awsui-visual-refresh">
        <App userInfo={userInfo} />
      </div>
    </React.StrictMode>
  );
}, () => {
  root.render(
    <React.StrictMode>
      <div>
        <Error />
      </div>
    </React.StrictMode>
  );
})
  .catch((e) => {
    root.render(
      <React.StrictMode>
        <div>
          <p>Some error has occured - {e}</p>
        </div>
      </React.StrictMode>
    );
  })

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
