import TopNavigation from "@amzn/awsui-components-react-v3/polaris/top-navigation";
import { NavigateFunction, useNavigate } from "react-router-dom";
import cb from "../img/cb.png"

export default function TopBar(props: any) {

  let navigate: NavigateFunction = useNavigate();

  const handleClick = async (event: any) => {
    event.preventDefault();
    navigate("/");
  }

  return (
    <TopNavigation
      identity={{
        href: "#",
        title: "Third Party Platform Security (TPPS) Portal",
        onFollow: handleClick,
        logo: {
          src: cb,
          alt: "Service"
        }
      }}
      utilities={[
        {
          type: "menu-dropdown",
          text: props.userInfo.alias,
          description: props.userInfo.email,
          iconName: "user-profile",
          items: [
            {
              id: "support-group",
              text: "Support",
              items: [
                {
                  id: "source_code",
                  text: "Source Code",
                  href: "https://code.amazon.com/version-sets/TppsPir-Service/development",
                  external: true,
                  externalIconAriaLabel:
                    " (opens in new tab)"
                },
                { id: "support", text: "Support", href: "https://w.amazon.com/bin/view/Infosec/TPPS/ContactTPPS/", external: true, externalIconAriaLabel: " (opens in new tab)" },
                {
                  id: "feedback",
                  text: "Feedback",
                  href: "https://w.amazon.com/bin/view/Infosec/TPPS/ContactTPPS/",
                  external: true,
                  externalIconAriaLabel:
                    " (opens in new tab)"
                }
              ]
            }
          ]
        }
      ]}
      i18nStrings={{
        searchIconAriaLabel: "Search",
        searchDismissIconAriaLabel: "Close search",
        overflowMenuTriggerText: "More",
        overflowMenuTitleText: "All",
        overflowMenuBackIconAriaLabel: "Back",
        overflowMenuDismissIconAriaLabel: "Close menu"
      }}

    />
  );
}