import React, { useEffect, useState } from 'react';
import {
  Box,
  Table,
  Button,
  Header,
  Pagination,
  TextFilter,
  CollectionPreferencesProps,
  CollectionPreferences
} from "@amzn/awsui-components-react-v3";

import { useCollection } from '@amzn/awsui-collection-hooks';
import { scan_api } from "../utils/constants";
import { columnDefList, preferenceOptions } from '../utils/mainTableDefs';
import { NavigateFunction, useNavigate } from 'react-router-dom';

// Helper function
const getFilterCounterText = (count: number | undefined) => `${count} ${count === 1 ? 'match' : 'matches'}`;

function EmptyState(props: any) {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {props.title}
      </Box>
      <Box variant="p" padding={{ bottom: 's' }} color="inherit">
        {props.subtitle}
      </Box>
      {props.action}
    </Box>
  );
}

// the MainTable react component
export default function MainTable(props: any) {

  let navigate: NavigateFunction = useNavigate();
  const handleClick = (event: any) => {
    event.preventDefault();
    navigate("register")
  }
  // declaring some useful states
  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({ pageSize: 10, visibleContent: ['id', 'app_name', 'submitter_alias', 'vendor_name'] });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [entries, setEntries] = useState<any[]>([]);

  // getData helper function
  async function getData(jwt: string) {
    let Data: any;
    Data = {};
    let request: any;
    request = {};
    request["action"] = "scan";

    try {
      const response = await fetch(scan_api, {
        mode: 'cors',
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          'Accept': 'application/json',
          'Authorization': jwt,
        },
        body: JSON.stringify(request)
      });

      response.json().then(data => {
        console.log(data);
        Data = data.Items;
        setEntries(Data);
        console.log(Data);
      }).then(() => setIsLoading(false))
    }
    // TODO: Handle the error more gracefully by adding a pop up
    catch (err) {
      console.log(err);
    }
  }

  // initilizing the useCollections hook
  const { items, actions, filterProps, filteredItemsCount, collectionProps, paginationProps } = useCollection(entries,
    {
      filtering: {
        empty: (
          <EmptyState
            title="No instances"
            subtitle="No instances to display."
            action={<Button onClick={handleClick}>New Registration</Button>}
          />
        ),
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="We can’t find a match."
            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
      selection: {},
    }
  );

  // fetching the data using useEffect hook
  useEffect(() => {
    // TODO: useEffect is running twice, this is obviously a BUG
    getData(props.userInfo.jwt);
  }, [props.userInfo.jwt]);

  const { selectedItems } = collectionProps;
  return (
    
    <Table
      {...collectionProps}
      selectionType="multi"
      header={
        <Header
          counter={selectedItems?.length ? `(${selectedItems.length}/${entries.length})` : `(${entries.length})`}
        >
          Instances
        </Header>
      }
      resizableColumns
      columnDefinitions={columnDefList}
      items={items}
      visibleColumns={preferences.visibleContent}
      loadingText="Loading resources"
      loading={isLoading}
      pagination={<Pagination {...paginationProps} />}
      filter={
        <TextFilter
          {...filterProps}
          countText={getFilterCounterText(filteredItemsCount)}
          filteringPlaceholder="Find entries"
          filteringAriaLabel="Filter instances"
        />
      }
      preferences={
        <CollectionPreferences className='awsui-visual-refresh'
          title="Preferences"
          confirmLabel="Confirm"
          cancelLabel="Cancel"
          preferences={preferences}
          onConfirm={({ detail }) => setPreferences(detail)}
          pageSizePreference={{
            title: "Select page size",
            options: [
              { value: 10, label: "10 resources" },
              { value: 20, label: "20 resources" }
            ]
          }}
          visibleContentPreference={{
            title: "Select visible content",
            options: preferenceOptions,
          }}
        />
      }
    />
  );
}
