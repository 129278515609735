export const columnDefList = [
  {
    id: "id",
    header: "ID",
    cell: (e: { id: any; }) => e.id,
    sortingField: "id",
  },
  {
    id: "time_created",
    header: "Time created",
    cell: (e: { time_created: any; }) => e.time_created,
    sortingField: "time_created"
  },
  {
    id: "app_name",
    header: "App name",
    cell: (e: { app_name: any; }) => e.app_name,
    sortingField: "app_name",
  },
  {
    id: "vendor_name",
    header: "Vendor's name",
    cell: (e: { vendor_name: any; }) => e.vendor_name,
    sortingField: "vendor_name"
  },
  {
    id: "vendor_website",
    header: "Vendor's website",
    cell: (e: { vendor_website: any; }) => e.vendor_website,
    sortingField: "vendor_website"
  },
  {
    id: "product_url",
    header: "Product website",
    cell: (e: { product_url: any; }) => e.product_url,
    sortingField: "product_url"
  },
  {
    id: "pii_phi",
    header: "Stores PII or PHI",
    cell: (e: { pii_phi: any; }) => e.pii_phi,
    sortingField: "pii_phi"
  },
  {
    id: "bindle",
    header: "Bindle",
    cell: (e: { bindle: any; }) => e.bindle,
    sortingField: "bindle"
  },
  {
    id: "primary_owner_alias",
    header: "Primary owner's alias",
    cell: (e: { primary_owner_alias: any; }) => e.primary_owner_alias,
    sortingField: "primary_owner_alias"
  },
  {
    id: "secondary_owner_alias",
    header: "Secondary owner's alias",
    cell: (e: { secondary_owner_alias: any; }) => e.secondary_owner_alias,
    sortingField: "secondary_owner_alias"
  },
  {
    id: "importance",
    header: "Importance",
    cell: (e: { importance: any; }) => e.importance,
    sortingField: "importance"
  },
  {
    id: "expected_records_in_year",
    header: "Expected records in year",
    cell: (e: { expected_records_in_year: any; }) => e.expected_records_in_year,
    sortingField: "expected_records_in_year"
  },
  {
    id: "expected_users_in_year",
    header: "Expected users in year",
    cell: (e: { expected_records_in_year: any; }) => e.expected_records_in_year,
    sortingField: "expected_users_in_year"
  },
  {
    id: "comments",
    header: "Comments",
    cell: (e: { comments: any; }) => e.comments,
    sortingField: "comments"
  },
  {
    id: "submitter_alias",
    header: "Submitter's alias",
    cell: (e: { submitter_alias: any; }) => e.submitter_alias,
    sortingField: "submitter_alias"
  },
  {
    id: "submitter_email",
    header: "Submitter's email",
    cell: (e: { submitter_email: any; }) => e.submitter_email,
    sortingField: "submitter_email"
  },
  {
    id: "submitter_department_id",
    header: "Submitter's department ID",
    cell: (e: { submitter_department_id: any; }) => e.submitter_department_id,
    sortingField: "submitter_department_id"
  },
  {
    id: "submitter_org",
    header: "Submitter's org",
    cell: (e: { submitter_org: any; }) => e.submitter_org,
    sortingField: "submitter_org"
  },
  {
    id: "primary_owner_email",
    header: "Primary owner's email",
    cell: (e: { primary_owner_email: any; }) => e.primary_owner_email,
    sortingField: "primary_owner_email"
  },
  {
    id: "primary_owner_department_id",
    header: "Primary owner's department ID",
    cell: (e: { primary_owner_department_id: any; }) => e.primary_owner_department_id,
    sortingField: "primary_owner_department_id"
  },
  {
    id: "primary_owner_org",
    header: "Primary owner's org",
    cell: (e: { primary_owner_org: any; }) => e.primary_owner_org,
    sortingField: "primary_owner_org"
  },
  {
    id: "secondary_owner_email",
    header: "Secondary owner's email",
    cell: (e: { secondary_owner_email: any; }) => e.secondary_owner_email,
    sortingField: "secondary_owner_email"
  },
  {
    id: "secondary_owner_department_id",
    header: "Secondary owner's department ID",
    cell: (e: { secondary_owner_department_id: any; }) => e.secondary_owner_department_id,
    sortingField: "secondary_owner_department_id"
  },
  {
    id: "secondary_owner_org",
    header: "Secondary owner's org",
    cell: (e: { secondary_owner_org: any; }) => e.secondary_owner_org,
    sortingField: "secondary_owner_org"
  }
]

export const preferenceOptions = [
  {
    label: "Main attribute list",
    options: [
      {
        id: "id",
        label: "ID",
        editable: false
      },
      {
        id: "time_created",
        label: "Time created"
      },
      { id: "app_name",
        label: "App name"
      },
      {
        id: "vendor_name",
        label: "Vendor's name"
      },
      {
        id: "vendor_website",
        label: "Vendor's website"
      },
      {
        id: "product_url",
        label: "Product website"
      },
      {
        id: "pii_phi",
        label: "Stores PII or PHI"
      },
      {
        id: "bindle",
        label: "Bindle"
      },
      {
        id: "primary_owner_alias",
        label: "Primary owner's alias"
      },
      {
        id: "secondary_owner_alias",
        label: "Secondary owner's alias"
      },
      {
        id: "importance",
        label: "Importance"
      },
      {
        id: "expected_records_in_year",
        label: "Expected records in year"
      },
      {
        id: "expected_users_in_year",
        label: "Expected users in year"
      },
      {
        id: "comments",
        label: "Comments"
      },
      {
        id: "submitter_alias",
        label: "Submitter's alias"
      },
      {
        id: "submitter_email",
        label: "Submitter's email"
      },
      {
        id: "submitter_department_id",
        label: "Submitter's deplartment ID"
      },
      {
        id: "submitter_org",
        label: "Submitter's org"
      },
      {
        id: "primary_owner_email",
        label: "Primary owner's email"
      },
      {
        id: "primary_owner_department_id",
        label: "Primary owner's department ID"
      },
      {
        id: "primary_owner_org",
        label: "Primary owner's org"
      },
      {
        id: "secondary_owner_email",
        label: "Secondary owner's email"
      },
      {
        id: "secondary_owner_department_id",
        label: "Secondary owner's department ID"
      },
      {
        id: "secondary_owner_org",
        label: "Secondary owner's org"
      },
    ]
  }
]