import {
  Header,
  AppLayout,
  ContentLayout,
} from "@cloudscape-design/components";
import SimpleRegistrationContent from './SimpleRegistrationContent';
import TopBar from '../../../components/TopBar';

export default function SimpleRegistration(props: any) {
 

  return (

    <div>
      <TopBar userInfo={props.userInfo} />
         
      <AppLayout
        navigationHide={true}
        toolsHide={true}
        contentType="form"
        content={
        <ContentLayout
            header={
                <Header
                    variant="h1">
                      Product Instance Registration
                    </Header>
            }>
        <SimpleRegistrationContent userInfo={props.userInfo} />
        </ContentLayout>
        }  
      />
      
  </div>
    
    

  );
}