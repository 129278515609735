import {
    CognitoAuth,
    CognitoAuthSession
} from 'amazon-cognito-auth-js';

const devLocalPort: number = 3000;
function getRedirectUri(): string {
    let uri: string = "https://theportal.security.amazon.dev/";
    if (isLocalhost)
        uri = `http://localhost:${devLocalPort}`
    return uri;
}

export default function AuthProvider() {

    // declaring the interfaces
    interface UserInfo {
        email: string,
        alias: string,
        jwt: string,
    }

    // initializing the interfaces
    let userInfo: UserInfo = {
        email: "",
        alias: "",
        jwt: "",
    };

    return new Promise((resolve, reject) => {

        const auth = new CognitoAuth({
            AppWebDomain: 'federate-dev-tpps-pir.auth.us-east-1.amazoncognito.com', // The domain name in Cognito "App integration > Domain name", without https://   
            ClientId: '2oqf61pvajb0j7akstunrgn9e5', // the client ID from Cognito "General settings > App clients" page     
            RedirectUriSignIn: getRedirectUri(), // exactly same as the callbacks URLS in Cognito "App integration > App client settings" page    
            RedirectUriSignOut: getRedirectUri(), // exactly same as the sign out URLS in Cognito "App integration > App client settings" page     
            TokenScopesArray: ['openid', 'email', 'profile'],    
            UserPoolId: 'us-east-1_Q7JUPWWXR', // the user pool from Cognito "General settings" page   

        });

        // const auth = new CognitoAuth({     
            //AppWebDomain: 'tpps-portal-main-pool.auth.us-east-1.amazoncognito.com', The domain name in Cognito "App integration > Domain name", without https://     
            //ClientId: '3tatiavbkfp8pjlc3lilioead7', // the client ID from Cognito "General settings > App clients" page     
            //RedirectUriSignIn: getRedirectUri(), // exactly same as the callbacks URLS in Cognito "App integration > App client settings" page     
            //RedirectUriSignOut: getRedirectUri(), // exactly same as the sign out URLS in Cognito "App integration > App client settings" page    
            //TokenScopesArray: ['openid', 'email', 'profile'],     
            //UserPoolId: 'us-east-1_KlwMUH1lZ', // the user pool from Cognito "General settings" page           
        // });









        // Make user you turn on OAuth2 Authorization Code Grant flow
        auth.useCodeGrantFlow();
        // Register callback functions
        auth.userhandler = {
            onFailure: (err: any) => {
                console.log(err);
                removeQueryFromLocation();
                // auth.signOut();
                reject(userInfo);
            },
            onSuccess: (result: CognitoAuthSession) => {
                console.log(result);
                removeQueryFromLocation();
                // You have logged in :-)
                getUserInfo();
                resolve(userInfo);

            },
        }
        /* 
        * this is the function responsible for populating the logged in user's
        * information.
        */
        function getUserInfo() {
            console.log(auth.getSignInUserSession().getIdToken().decodePayload());
            let payload: any = auth.getSignInUserSession().getIdToken().decodePayload();
            let jwt = auth.getSignInUserSession().getIdToken().getJwtToken();
            console.log(jwt);
            console.log(payload.identities[0].userId);
            userInfo.email = payload.email;
            userInfo.alias = payload.identities[0].userId;
            userInfo.jwt = jwt;
        }
        // This code removes the "?code=..." from the URL. It is because the grant code is not reusable. Sometimes
        // the SDK will report weird message because of using old grant code.
        function removeQueryFromLocation() {
            // Replace the href because the Cognito passes the OAuth2 grant code in the query string
            // And the grant code is not reusable
            if (window.history.length > 0) {
                const newHref = window.location.href.split('?')[0];
                window.history.replaceState(undefined, 'Your App Title', newHref);
            }
        }

        const href = window.location.href;
        const session = auth.getSignInUserSession();

        if (session.isValid()) {
            removeQueryFromLocation();
            console.log("session is valid")
            getUserInfo();
            resolve(userInfo);
        } else if (href.indexOf('?') > 0) {
            // This is required because Cognito needs to get the authentication result from the query string
            // The parsing is done asynchronously, and the result will be passed to the userHandler.
            auth.parseCognitoWebResponse(href);
        } else {
            // Cognito SDK will handle session refresh / authentication.
            console.log("refresh session");
            auth.clearCachedTokensScopes();
            auth.getSession();
        }
    })
}

const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);